export enum TabelaPrecoParcela {
  PARCELA_0 = '0',
  PARCELA_1 = '1',
  PARCELA_2 = '2',
  PARCELA_3 = '3',
  PARCELA_4 = '4',
  PARCELA_5 = '5',
  PARCELA_6 = '6',
  PARCELA_7 = '7',
  PARCELA_8 = '8',
  PARCELA_9 = '9',
  PARCELA_10 = '10',
  PARCELA_11 = '11',
  PARCELA_12 = '12',
  PARCELA_17 = '17',
  PARCELA_18 = '18',
  PARCELA_23 = '23',
  PARCELA_24 = '24',
  PARCELA_29 = '29',
  PARCELA_30 = '30',
  PARCELA_35 = '35',
  PARCELA_36 = '36',
  PARCELA_47 = '47',
  PARCELA_48 = '48',
  PARCELA_59 = '59',
  PARCELA_60 = '60',
}
