type LoteContratos = {
  cadastroId: string;
  proprietario: string;
};

export class LoteListarVendasDto {
  id: number;
  label: string;
  quadra: string;
  contratosVendidosTotal: number;
  contratos: LoteContratos[];

  constructor(
    id: number,
    label: string,
    quadra: string,
    contratosVendidosTotal: number,
  ) {
    this.id = id;
    this.label = label;
    this.quadra = quadra;
    this.contratosVendidosTotal = contratosVendidosTotal;
  }
}
