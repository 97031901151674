import { Component, Inject, Input, OnInit } from '@angular/core';
import { IMAGES_PATH } from '@usucampeao/utils-frontend';
import { DocumentoService } from '../../../services';
import { ToastService } from '../../../shared';
import { Arquivo, UploadFotoComponent } from '../upload-foto.component';

@Component({
  selector: 'usucampeao-upload-foto-documento',
  templateUrl: './upload-foto-documento.component.html',
  styleUrls: ['./upload-foto-documento.component.scss']
})
export class UploadFotoDocumentoComponent extends UploadFotoComponent implements OnInit {
  @Input() modoSimplificado = false;
  @Input() readonly = false;

  constructor(
    @Inject(IMAGES_PATH) protected imagesPath: string,
    private readonly documentoService: DocumentoService,
    protected readonly toastService: ToastService,
  ) {
    super(toastService);
  }

  ngOnInit(): void {
    const arquivoSalvoStorage = this.documentoService.obterDocumentoStorage();
    if (arquivoSalvoStorage) {
      this.resetar();
      this.gerarArquivoEEmitirUpload(arquivoSalvoStorage.base64, arquivoSalvoStorage.formatoArquivo, arquivoSalvoStorage.arquivoPdf);
    }
  }
  public get imagemDefault(): string {
    return `${this.imagesPath}/placeholder.png`;
  }

  public get imagemCarregando(): string {
    return `${this.imagesPath}/defaultImage.svg`;
  }

  public get possuiImagem(): boolean {
    return (this.imagensBase64 as Arquivo[]).length > 0;
  }

  public get arquivoPDF(): boolean {
    return (this.imagensBase64 as Arquivo[])[this.indiceImagemSelecionada]?.arquivoPdf;
  }

  public get mostrarBotaoVoltar(): boolean {
    return this.indiceImagemSelecionada > 0 && !this.readonly;
  }

  public get mostrarBotaoAvancar(): boolean {
    return (this.indiceImagemSelecionada + 1 < (this.imagensBase64 as Arquivo[]).length) && !this.readonly;
  }
}
