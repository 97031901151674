
export interface Formatador {
  formatar(texto: string): string;
}

export const REGEX_PATTERNS = {
  /**
   * Formatação da string no formato do CPF: 12345678909 -> 123.456.789-09
   */
  CPF_FORMAT: /(\d{3})(\d{3})(\d{3})(\d{2})/,
  /**
   * Formatação da string no formato do CNPJ: 08940956000185 -> 08.940.956/0001-85
   */
  CNPJ_FORMAT: /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
  /**
   * Formatação da string no formato do telefone: 11912345678 -> (11) 91234-5678
   */
  PHONE_FORMAT: /(\d{2})(\d{4,5})(\d{4})/,
  /**
   * Expressão regular para encontrar caracteres especiais.
   */
  SPECIAL_CHARACTERS: /[\W_]+/g,
  /**
   * Expressão regular para encontrar caracteres especiais, exceto letras acentuadas, hífens, espaços e pontos.
   */
  SPECIAL_CHARACTERS_EXCEPT_ALLOWED: /[^a-zA-ZÀ-ÿ0-9\.\-\s]/g,
  /**
   * Expressão regular para encontrar caracteres alfanuméricos com letras acentuadas, hífens, espaços e pontos.
   */
  ALPHANUMERIC_WITH_SPECIAL_CHARACTERS: /^[a-zA-ZÀ-ÿ0-9\.\-\s]*$/,
  /**
   * Expressão regular para encontrar caracteres não numéricos.
   */
  NON_NUMERIC_CHARACTERS: /\D/g,
}

/**
 * Classe base para formatação de strings.
 */
export abstract class FormatadorBase implements Formatador {
  abstract formatoInput: RegExp;
  abstract formatoOutput: string;

  /**
   * Formata um texto de acordo com o formato especificado.
   *
   * @param texto O texto a ser formatado.
   * @returns O texto formatado.
   * @throws {FormatadorInputInvalidoError} Se o texto informado for inválido.
   */
  public formatar(texto: string): string {
    if (!texto) {
      throw new FormatadorInputInvalidoError(texto);
    }
    return texto.replace(this.formatoInput, this.formatoOutput);
  }
}

/**
 * Classe responsável por adicionar formatação de CPF.
 * Exemplo: 12345678909 -> 123.456.789-09
 */
class FormatadorCPF extends FormatadorBase {
  formatoInput = REGEX_PATTERNS.CPF_FORMAT;
  formatoOutput = '$1.$2.$3-$4';
}

/**
 * Classe responsável por adicionar formatação de CNPJ.
 * Exemplo: 08940956000185 -> 08.940.956/0001-85
 */
class FormatadorCNPJ extends FormatadorBase {
  formatoInput = REGEX_PATTERNS.CNPJ_FORMAT;
  formatoOutput = '$1.$2.$3/$4-$5';
}

/**
 * Classe responsável por adicionar formatação para número de telefone.
 * Caso o número de telefone tenha 11 dígitos, a formatação será (XX) XXXXX-XXXX.
 * Caso o número de telefone tenha 10 dígitos, a formatação será (XX) XXXX-XXXX.
 * Exemplo: 11912345678 -> (11) 91234-5678 / 1112345678 -> (11) 1234-5678
 */
class FormatadorTelefone extends FormatadorBase {
  formatoInput = REGEX_PATTERNS.PHONE_FORMAT;
  formatoOutput = '($1) $2-$3';
}

/**
 * Classe responsável por remover caracteres especiais de uma string.
 * Esta classe removerá todos os caracteres que não são letras ou números, inclusive letras acentuadas.
 * Exemplo: 08.940.956/0001-85 -> 08940956000185
 */
class FormatadorRemoverCaracteresEspeciais extends FormatadorBase {
  formatoInput = REGEX_PATTERNS.SPECIAL_CHARACTERS;
  formatoOutput = '';
}

/**
 * Classe responsável por remover caracteres especiais de uma string,
 * exceto letras acentuadas, hífens e pontos.
 * Exemplo: "Olá, mundo! Vamos-remover*caracteres especiais."
 * Resultado: "Olá mundo Vamos-removercaracteres especiais."
 */
class FormatadorRemoverCaracteresEspeciaisExcetoPermitidos extends FormatadorBase {
  formatoInput = REGEX_PATTERNS.SPECIAL_CHARACTERS_EXCEPT_ALLOWED;
  formatoOutput = '';
}

/**
 * Classe responsável por remover caracteres não numéricos de uma string.
 * Exemplo: 1A348.932BE32 -> 134893232
 */
class FormatadorRemoverCaracteresNaoNumericos extends FormatadorBase {
  formatoInput = REGEX_PATTERNS.NON_NUMERIC_CHARACTERS;
  formatoOutput = '';
}

/**
 * Constante que contém todos os formatadores disponíveis.
 */
export const FORMATADORES = {
  cpf: new FormatadorCPF(),
  cnpj: new FormatadorCNPJ(),
  telefone: new FormatadorTelefone(),
  removerCaracteresEspeciais: new FormatadorRemoverCaracteresEspeciais(),
  removerCaracteresEspeciaisExcetoPermitidos: new FormatadorRemoverCaracteresEspeciaisExcetoPermitidos(),
  removerCaracteresNaoNumericos: new FormatadorRemoverCaracteresNaoNumericos(),
}

export class FormatadorInputInvalidoError extends Error {
  constructor(input: string) {
    super(`Input informado inválido para realizar formatação. ($Input=${input})`);
    this.name = 'FormatadorInputInvalidoError';
  }
}
