import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentFileDto, DocumentFileType, FileDto, StatusPreenchimento } from '@usucampeao/lib-reurb-simplificado';
import { IMAGES_PATH } from '@usucampeao/utils-frontend';
import { filter, tap } from 'rxjs/operators';
import { DocumentoService } from '../../../services';
import { ToastService } from '../../../shared';
import { Arquivo, UploadFotoComponent } from '../upload-foto.component';

@Component({
  selector: 'usucampeao-upload-foto-documento-v2',
  templateUrl: './upload-foto-documento-v2.component.html',
  styleUrls: ['./upload-foto-documento-v2.component.scss']
})
export class UploadFotoDocumentoV2Component extends UploadFotoComponent {
  @Input() set documento(documento: DocumentFileDto) {
    this._documento = documento;
    if (this._documento) {
      this.documentoTipo = this.documentoTipo ?? this._documento.type;
      this.carregarArquivosDocumento();
    } else {
      this.arquivosDocumento = [];
    }
  }
  @Input() titulo: string;
  @Input() statusPreenchimento: StatusPreenchimento;
  @Input() listaDeDocumentos: DocumentFileType[];
  @Input() documentoTipo: DocumentFileType;
  @Input() readonly = false;
  @Input() accordionEstaAberto = false;

  @Output() aoSelecionarDocumento = new EventEmitter<DocumentFileType>();

  private _documento: DocumentFileDto;
  public form: FormGroup;
  public arquivosDocumento: Arquivo[] = [];

  constructor(
    private readonly documentoService: DocumentoService,
    @Inject(IMAGES_PATH) protected imagesPath: string,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    protected readonly toastService: ToastService,
  ) {
    super(toastService);
    this.form = this.formBuilder.group({
      documento: [null, Validators.required]
    });
  }

  public get imagemDefault(): string {
    return `${this.imagesPath}/placeholder.png`;
  }

  public get imagemCarregando(): string {
    return `${this.imagesPath}/defaultImage.svg`;
  }

  public get possuiImagem(): boolean {
    return (this.imagensBase64 as Arquivo[]).length > 0;
  }

  public get arquivoPDF(): boolean {
    return (this.imagensBase64 as Arquivo[])[this.indiceImagemSelecionada]?.arquivoPdf;
  }

  public get mostrarBotaoVoltar(): boolean {
    return this.indiceImagemSelecionada > 0 && !this.readonly;
  }

  public get mostrarBotaoAvancar(): boolean {
    return (this.indiceImagemSelecionada + 1 < (this.imagensBase64 as Arquivo[]).length) && !this.readonly;
  }

  public get mensagemErro(): string {
    const { documento } = this.form.controls;
    return documento.hasError('required') && documento.touched ? 'Campo obrigatório' : '';
  }

  public carregarArquivosDocumento(): void {
    this.documentoService.buscarArquivosDocumento(this._documento.id)
      .pipe(
        filter(arquivos => !!arquivos?.length),
        tap(arquivos => this.configurarArquivosDocumento(arquivos)),
      )
      .subscribe();
  }

  public configurarArquivosDocumento(arquivos: FileDto[]): void {
    if (!arquivos) {
      this.arquivosDocumento = [];
    } else {
      this.arquivosDocumento = arquivos.map(arquivo => ({
        id: arquivo.id,
        arquivoPdf: arquivo.mimeType.includes('pdf'),
        base64: `/documents/${this._documento.id}/files/${arquivo.id}/download`
      }));
    }
  }

  public abrirCamera(): void {
    this.form.markAllAsTouched();
    if (
      !this.readonly &&
      this.listaDeDocumentos?.length &&
      this.form.invalid
    ) {
      return;
    }
    this.resetar();
    this.uploadEstaDesabilitado = false;
    const { documento } = this.form.value;
    this.aoSelecionarDocumento.emit(documento);
    super.selecionarImagem();
  }

  public irParaUploadDeDocumento(): void {
    this.router.navigate(
      ['../envio-documentos/upload'],
      {
        queryParams: {
          tipo: this._documento.type,
          documentoId: this._documento.id,
        },
        relativeTo: this.route,
      }
    );
  }

  public alterarAberturaAccordion(estaAberto: boolean): void {
    this.accordionEstaAberto = estaAberto;
  }
}
