import { SearchProps } from '@usucampeao/utils';
import { PagamentoStatus } from '.';

export interface PagamentoListagemMensalSearchParams extends SearchProps {
  /**
   * Data selecionada para a listagem dos pagamentos.
   * Será extraído o mês e o ano para a busca.
   */
  data_selecionada: Date;
}

export class PagamentoListagemMensalDto {
  id: string;
  cadastro_id: string;
  projeto_id: string;
  projeto: string;
  morador: string;
  parcela: number;
  status: PagamentoStatus;
  data_vencimento: Date;
  valor_devido: number;
  data_pagamento?: Date;
  valor_pago?: number;
}

export class PagamentoListagemMensalDetalhadoDto extends PagamentoListagemMensalDto {
  cadastro_data_contrato_gerado: Date;
  cadastro_data_contrato_assinatura: Date;
  total_parcelas: number;
  data_emissao?: string;
  meio_pagamento: string;
  gateway?: string;
  cadastro_url?: string;
}
